if(!window.vars){
    window.vars = {};
}

if(!window.utils){
    window.utils = {};
}


window.utils.toFixedNumber = function(number,compareVal) {
    if(number < compareVal){
        number = String(number);
        compareVal = String(compareVal);
        let counter = compareVal.length - number.length;
        for(var i = counter;i > 0;i--){
            number = '0' + number;
        }
    }
    return number;
};

if(!window.functions){
    window.functions = {};
}

window.functions.initLaydate = function(el,format = 'yyyy-MM-dd HH:mm:ss',defaultTime = '23:59:59',fnEnd = ()=>{}){
    if(!laydate){
        throw new Error('laydate is not loaded');
    }
    
    var year = new Date().getFullYear() + '',
        month = window.utils.toFixedNumber((new Date().getMonth() + 1),10) + '',
        day = window.utils.toFixedNumber(new Date().getDate(),10) + '',
        separatorYear = format[format.indexOf('yyyy') + 4],
        separatorMonth = format[format.indexOf('MM') + 2],
        separatorDay = String(format[format.indexOf('dd') + 2] || '').trim() || '',
        currentDay = year + separatorYear + month + separatorMonth + day + separatorDay,
        isInitValue = !!$(el).val(),
        value = isInitValue ? $(el).val() : currentDay + ' ' + defaultTime,
        originTextColor = $(el).css('color');
    
    //执行一个laydate实例
    $(el).css({
        color: 'transparent',
    })
    
    let instance = laydate.render({
        elem: el,
        format: format,
        type: 'datetime',
        isInitValue: isInitValue,
    });
    setTimeout(()=>{
        if(!isInitValue){ //isInitValue 配置不生效
            $(el).attr({
                value: ''
            }).val('');
            $(el).css({
                color: originTextColor,
            })
        }
        fnEnd(el,instance);
    },150);
    
};


;(function($){
    //tab-item用active类名来表示高亮
    //tab-item-content使用current表示显示
	var Tab = function(tab){
		var _this = this;
		// 保存单个tab组件
		this.tab = tab;
		// 默认配置参数
		this.config = {
			"triggerType":"mouseover", // 用来定义鼠标的触发类型，是click还是mouseover
			"effect":"default", // 用来定义内容切换效果，是直接切换还是淡入淡出效果
			"invoke":1, // 默认展示第几个tab
			"auto":false // 定义tab是否自动切换，及自动切换时间间隔
		}
		// 如果配置参数存在，就扩展掉默认配置参数
		if(this.getConfig()){
			$.extend(this.config,this.getConfig());
		}
		
		// 保存tab标签列表、对应的内容列表
		this.tabItems = this.tab.find(".tab-item");
		this.contentItems = this.tab.find(".tab-item-content");

		// 保存配置参数
		var config = this.config;

		if(config.triggerType === "click"){
			this.tabItems.bind(config.triggerType,function(){
				_this.invoke($(this));
			})
		}else if(config.triggerType != "click"){
			this.tabItems.bind("mouseover",function(){
				_this.invoke($(this));
			})
		}

		// 自动切换功能，如果配置了时间，我们就根据时间间隔进行自动切换
		if(config.auto){
			// 定义一个全局的定时器
			this.timer = null;
			// 计数器
			this.loop = 0;

			this.autoPlay();

			this.tab.hover(function(){
				window.clearInterval(_this.timer);
			},function(){
				_this.autoPlay();
			})
		}

		// 设置默认显示第几个tab
		if(config.invoke > 1){
			this.invoke(this.tabItems.eq(config.invoke-1));
		}
	};

	Tab.prototype = {
		// 自动间隔时间切换
		autoPlay: function(){
			var _this = this,
				tabItems = this.tabItems, // 临时保存tab列表
				tabLength = tabItems.size(), // tab的个数
				config = this.config;
			this.timer = window.setInterval(function(){
				_this.loop ++;
				if(_this.loop >= tabLength){
					_this.loop = 0;
				};
				tabItems.eq(_this.loop).trigger(config.triggerType);
			},config.auto);
		},

		// 事件驱动函数
		invoke: function(currentTab){
			var _this = this;
            var index = currentTab.index();
			// tab选中状态
			currentTab.addClass('active').siblings().removeClass('active');
			// 切换对应的内容区域
			var effect = this.config.effect;
			var conItems = this.contentItems;
			if(effect === "default" || effect != "fade"){
				conItems.eq(index).addClass('current').siblings().removeClass('current');
			}else if(effect === "fade"){
				conItems.eq(index).fadeIn().siblings().fadeOut();
			};
			// 注意：如果参数配置了自动切换时间，要把当前的loop的值设置成当前tab的index
			if(this.config.auto){
				this.loop = index;
			}
		},

		// 获取配置参数
		getConfig: function(){
			// 拿一下tab elem节点上的data-config
			var config = this.tab.attr("data-config");
            // 确保有配置参数
			if(config&&config!=""){
				return $.parseJSON(config);
			}else{
				return null;
			}
		}
	};
	var MenuIcon = function (node,options){
		this.node = $(node);
		this.options = options;
		var _this = this;
		this._li = this.node.find("li");
		this.init();
		this.node.find('li .item').click(function(){
			_this.nodeClick($(this).parent('li'));
		});
	}
	
	MenuIcon.prototype.init = function(){
		this.node.find('.item-content').hide();
		this.node.find('.item-content').each(function(){
			if($(this).data('show') == 1) {
				$(this).show();
			}
		});
		this.node.find('.item .iconfont:last-child').addClass(this.options.hide);
	}
	
	MenuIcon.prototype.nodeClick = function(_this){
		var itemContent = _this.find(".item-content"),
			toggles = itemContent.siblings(".item").find(".iconfont:last");
	
		this.node.find('.item').find(".iconfont:last").removeClass(this.options.show).addClass(this.options.hide);
		if(itemContent.css("display") == "none"){
			this.node.find('.item-content').hide(300);
			toggles.removeClass(this.options.hide).addClass(this.options.show);
			itemContent.slideDown();
		}else{
			itemContent.slideUp();
			toggles.removeClass(this.options.show).addClass(this.options.hide);
		}
	}
	//图片验证与发送手机验证码
	var Verification = function (form,settings) {  
		var _this = this;
		this.wait = 60;
		this.form = form;
		this.config = settings;
		// settings必须包含img和url
		if(!settings.img || !settings.url){
			console.error('请检查配置参数是否正确。');
		}
		form.find('.validate-img').bind('click',function(){
			_this.loadImg(form,_this);
		})
		form.find('.send_code').bind('click',function(e){
			console.log(_this.sendCode)
			_this.sendCode(form,e,_this);
		})
	}
	Verification.prototype = {
		time: function (o,self) {
			var _this=this;
			if (self.wait == 0) {         
				o.html("发送验证码");
				self.wait = 60;
				o.attr('disabled', false);
			} else {
				o.html(self.wait + " s");
				self.wait--;
				setTimeout(function() {
					_this.time(o,self)
				},
				1000)
			}
		},
		loadImg: function (form,self) {  
			var img = form.find('.validate-img > img').attr('src', self.config.img)
				.load(function() {
					if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth == 0) {
						alert('验证码图片加载出错，请刷新');
					} else {
						form.find('.validate-img').html('<i class="icon-refresh"></i>').prepend(img);
					}
				});
			return false;
		},
		sendCode: function (form,e,self) {
			var _this=this;
			var code = form.find('.input-default input[name="verify"]').val();//验证码
            	e.preventDefault();
                if(self.wait != 60){
                    return;
                }
                var btn_o = form.find('a.send_code');
				var mobile = form.find('input[name=email]').val();
                $.ajax({
                    url: self.config.url + "?email=" + mobile + '&code=' + code,
                    beforeSend: function() {
                        btn_o.html('加载中');
                        btn_o.attr('disabled', true);
                        return true;
                    },
                    success: function(data) {
                        //成功状态，下面的是服务器返回的，数据库操作的状态
                        if(data.status == 1){
                            _this.time(btn_o,self);
                        }
                        else{
                            btn_o.html('发送验证码 <br> Send Verification code');
                            btn_o.attr('disabled', false);
							self.loadImg(form,self);
                        }
                        $.msgBox({
                            title: "提示",
                            content: data.info,
                            type: "info",
                        });
                    },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                    	$.msgBox({
                            title: "错误",
                            content: $(XMLHttpRequest.responseText).find('h1').text(),
                            type: "error",
                        });
                        btn_o.html('获取验证码');
                        btn_o.attr('disabled', false);
						self.loadImg(form,self);                    
                    },
                });
		}
	}

	// 注册成jq方法
	$.fn.extend({
		tab: function(){
			this.each(function(){
				new Tab($(this));
			});
			return this;
		},
		verification: function (obj) {  
			this.each(function(){
				new Verification($(this),obj);
			});
			return this;
		}
	})

	window.Tab = Tab;
	window.Verification = Verification;
	window.MenuIcon = MenuIcon;
})(jQuery);
(function ($) {
    $.fn.countup = function (params) {
      // make sure dependency is present
      if (typeof CountUp !== 'function') {
        console.error('countUp.js is a required dependency of countUp-jquery.js.');
        return;
      }
  
      var defaults = {
        startVal: 0,
        decimalPlaces: 0,
        duration: 4,
      };
      if (typeof params === 'number') {
          defaults.endVal = params;
        }
      else if (typeof params === 'object') {
        $.extend(defaults, params);
      }
      this.each(function (i, elem) {
          let newEndVal = 0;
          newEndVal = defaults.endVal ? defaults.endVal : $(elem).data('number');
          console.log(defaults);
          var countUp = new CountUp(elem, newEndVal, defaults);
        countUp.start();
      });
  
      return this;
    };
  
  }(jQuery));
class AjaxPage{
    constructor(el, option = {}){
        this.$el = $(el);
        this.$pageEl = this.$el.find('a');
        this.initPage = this.$el.find('.page-number.current').text().trim();
        this.totalPage = this.$el.data('total-page');
        this.page = this.initPage;
        this.url = '';
        this.lockStatus = false;

        this.$ajaxPageBox = this.$el.prev();
        this.domIndex = this.$el.index('.ajax-page');

        this.$parent = this.$el.parent();

        let defOpt = {
            activePageClass: 'current',
        };

        this.option = Object.assign({}, defOpt, option);

        this.init();
    }

    init(){
        let that = this;

        this.initDom();
    
        this.$el.find('input[name=page]').on('keyup', function (e) {
            if(e.keyCode === 13){
                that.$el.find('a.confirm').trigger('click');
            }
        });

        this.$pageEl.on('click', function (e) {
            e.preventDefault();
            if(that.isLock()){
                return false;
            }
            that.lock();

            let $this = $(this);

            let newPage = that.getPage($this);
            if(!newPage){
                return false;
            }

            that.$parent.addClass('ajax-page-loading__active');
            $this.siblings().removeClass(that.option.activePageClass);
            $this.addClass(that.option.activePageClass);

            that.url = that.getUrl(newPage);

            $("html,body").animate({
                scrollTop: that.$parent.offset().top - 150
            }, 300);

            that.getData().then((res)=> {

                let $newDomPage = $(res).find('.ajax-page').eq(that.domIndex);

                that.$ajaxPageBox.empty()
                    .html($newDomPage.prev().children());
                that.$parent.removeClass('ajax-page-loading__active');

                let newId = `ajax-page-${parseInt(new Date().getTime() * Math.random() * Math.random())}`;
                $newDomPage.attr('id', newId);
                that.$el.replaceWith($newDomPage);
                new AjaxPage(`#${newId}`, that.option);

                that.unLock();
            });
        });

    }

    updatePage(){

    }

    initDom(){
        if(this.$parent.hasClass('ajax-page-loading')){
            return false;
        }
        this.$parent.addClass('ajax-page-loading')
            .append(`
            <i class="icon ajax-page-loading-icon"></i>
            <div class="loading-mask"></div>
        `);
    }

    isLock(){
        return this.lockStatus;
    }

    lock(){
        this.lockStatus = true;
        return true;
    }

    unLock(){
        this.lockStatus = false;
    }

    getPage($el){
        let opera = $el.data('opera');
        let page = this.page;
        switch (opera) {
            case 'prev':
                --page;
                break;
            case 'pages':
                page = $el.text().trim();
                break;
            case 'next':
                ++page;
                break;
            case 'custom':
                page = $el.prevAll('input[name=page]').val();
                break;
            default:
                page = 1;
        }
        page = Math.max(1, page);
        page = Math.min(this.totalPage, page);

        this.page = page;
        return page;
    }

    getData(){
        return $.ajax({
            url: this.url,
        }).success((res)=>{
            return res;
        });
    }

    getUrl(page){
        var search = window.location.search;
        var url=window.location.href;
        if (search.indexOf('?')===-1){
            search='?page='+page;
            url+=search;
        }else if (search.indexOf('page=')===-1){
            url+='&page='+page;
        }else{
            url=url.replace(/page=\d+/,'page='+page);
        }
        return url;
    };
}

$(function () {
    var togglePage = function (e) {
        if(e.keyCode === 13){
            $('.pagination-box').not('.ajax-page').find('.confirm').trigger('click');
        }
    };
    $('.pagination-box input[name=page]').on('focus', function () {
        $(window).on('keyup', togglePage);
    }).on('blur', function () {
        $(window).off('keyup', togglePage);
    });

    var getUrl = function(page){
        var search=window.location.search;
        var url=window.location.href;
        if (search.indexOf('?')===-1){
            search='?page='+page;
            url+=search;
        }else if (search.indexOf('page=')===-1){
            url+='&page='+page;
        }else{
            url=url.replace(/page=\d+/,'page='+page);
        }
        return url;
    };

    $('.pagination-box').not('.ajax-page').find('.confirm').on('click',function () {
        var page=$(this).prevAll('input[name=page]').val();
        var totalPage = $(this).parents('.pagination-box').data('total-page');
        page = parseInt(page);
        if (!page){
            return;
        }
        page = Math.max(0, page);
        page = Math.min(totalPage, page);
        var url = getUrl(page);
        $(this).attr('href',url);
        window.location.href=url;
    });

    $('.ajax-page').each(function () {
        new AjaxPage(this);
    });
});

//可滚动的导航栏
class SwiperNav {
  constructor(options) {
    this.defaultOptions = {
      container: '.swiper_nav_container',
      navData: [],
      navScroll: null,
      createDomAfter: function () {},
      clickHandle: function () {},
      customSwiper: null
    };
    this.options = Object.assign(this.defaultOptions, options);
    this.init();
  }
  loopSlide() {
    let html = '';
    this.options.navData.forEach((item) => {
      html += `<div class="swiper-slide">${item.name}</div>`;
    });
    return html;
  }
  createSwiperDom() {
    //自定义swiper内容
    if(this.options.customSwiper && typeof this.options.customSwiper === 'function') {
      $(this.options.container).html(this.options.customSwiper());
    }else{
      $(this.options.container).html(
        `
              <div class="swiper-container">
                  <div class="swiper-wrapper">
                      ${this.loopSlide()}
                      <span></span>
                  </div>
              </div>
              `
      );
    }
  }
  moveActive() {
    let clickedSlide = this.navScroll.clickedSlide || document.querySelector(this.options.container + ' .swiper-slide.active');
    console.log(clickedSlide);
    if(clickedSlide) {
      let thisWidth = clickedSlide.offsetWidth;
      let thisLeft = clickedSlide.offsetLeft;
      let offsetX = this.navScroll.width / 2 - thisLeft - thisWidth / 2;
      //偏移量在1/2视口内时，容器不发生偏移
      if (offsetX > 0) {
        offsetX = 0;
      }
      //offsetX小于容器最大偏移时，让偏移量等于容器最大的偏移量
      else if (offsetX < this.navScroll.maxTranslate()) {
        offsetX = this.navScroll.maxTranslate();
      }
      //设置容器的过渡动画
      this.navScroll.setTransition(300);
      this.navScroll.setTranslate(offsetX);
      //滚动条位置和长度执行动画
      $('.swiper-wrapper span').animate(
        { left: thisLeft, width: thisWidth },
        300
      );
      this.navScroll.clickedSlide = undefined;
    }
  }
  slideHandle() {
    if(this.options.clickHandle && typeof this.options.clickHandle === 'function'){
      this.options.clickHandle(this.navScroll);
    }
    this.moveActive();
  }
  init() {
    this.createSwiperDom();
    this.options.createDomAfter();
    $('.swiper-wrapper span').css({ width: $(`${this.options.container} .swiper-slide`)[0].offsetWidth });
    let that = this;
    this.navScroll = new Swiper(`${this.options.container} .swiper-container`, {
      freeMode: true,
      slidesPerView: 'auto',
      freeModeMomentumRatio: 0.5,
      // navigation: {
      //   nextEl: `${this.options.container} .swiper-button-next`,
      //   prevEl: `${this.options.container} .swiper-button-prev`,
      // },
      on: {
        //当前swiper-slide点击时触发事件
        click: function (e) {
          that.slideHandle();
        },
      },
    });
  }
}

class PosFixed{
    //必须使用 transform 作为 显示/隐藏的样式 不然ios 会等到滚动结束才显示
    //部分样式在 mobile/src/common.less
    constructor(el, config = {}) {
        this.$el = $(el);
        this.$elClone = this.$el.clone(true).addClass('posfixed-clone');
        this.defConf = {
            onFixed: ()=>{},
            onUnFixed: ()=>{},
            distance: 0, //距离目标 ${distance} px 提前触发
        };
        this.conf = Object.assign({},this.defConf,config);
        this.isShow = false;
        this.currentStatus = undefined;
        this.$win = $(window);
        this.init();
    }

    init(){
        if(!this.$el.length) return false;
        if(this.$el.parent().css('position') === 'static'){
            this.$el.parent().css({
                position: 'relative'
            });
        }
        this.offsetTop = this.$el.offset().top;
        this.$el.after(this.$elClone);
        this.$win.on('scroll', ()=> {
            this.scroll();
        });
        this.scroll();
        
    }

    scroll(){
        this.isShow = this.offsetTop - this.$win.scrollTop() - this.conf.distance <= 0;
        if(this.isShow !== this.currentStatus){
            if(this.isShow){
                this.$elClone.addClass('active');
                this.conf.onFixed();
            }else{
                this.$elClone.removeClass('active');
                this.conf.onUnFixed();
            }
            this.currentStatus = this.isShow;
        }
    }
}

(function () {
    //部分tab 使用了 js 动态高亮，得等到js 执行完毕
    setTimeout(()=> {
        new PosFixed('.posfixed');
    });
})();

class DownloadHtml2Image{
    constructor(btnSelector, config) {
        this.btnSelector = btnSelector;

        // 获取按钮选择器的dom元素
        this.$btnSelector = $(btnSelector);

        // 默认的配置
        this.DEFAULT_CONFIG = {
            touchSaveImage: false,  // 设置按钮一开始不可点击
            fileName: 'richer.png', // 保存图片的文件的名字
        };

        // 将用户传递过来的配置信息同默认配置信息进行比较配置，拷贝
        this.config = Object.assign({}, this.DEFAULT_CONFIG, config);

        // 二维码图片的元素，获取html元素而非jquery元素
        this.qrCodeDom = $(this.config.qrCodeDom).get(0);

        // 存放图片的url字符串
        this.IMG_DATA = null;

        this.init();
    }

    init(){

        // 先设置按钮不可点击
        this.$btnSelector.attr('disabled', true);

        
        if(!this.config.touchSaveImage){
            this.initClickSaveImg();
        }


        this.initFile().then(()=>{
            this.setBtnEnable();
        }).then(()=>{
            if(this.config.touchSaveImage){
                this.$btnSelector.css({
                    cursor: 'auto',
                });
                var NEW_IMG_DOM = `<img src="${this.IMG_DATA}" />`;
                $(this.config.imgBoxSelector).empty().append(NEW_IMG_DOM);
            }
        });
    }

    initClickSaveImg(){
        if(this.qrCodeDom){
            this.imgLoad(this.qrCodeDom).then(()=>{
                this.bindClickEvent();
            });
        }else{
            this.bindClickEvent();
        }
    }

    imgLoad(img){
        if(img.complete){
            return Promise.resolve();
        }
        return new Promise((resolve, reject)=>{
            return $(img).on('load', resolve);
        });
    }

    // 获取dom对象的canvas
    createImg(){
        return html2canvas(document.querySelector(this.config.imgBoxSelector),{
            useCORS: true,
            // async: false,
        });
    }

    setBtnDownload(){
        this.$btnSelector.text('已下载').attr('disabled', true);
        setTimeout( ()=> {
            this.$btnSelector.text(this.config.downloadText).attr('disabled', false);
        },3000);
    }

    setBtnEnable(){
        this.$btnSelector.attr({
            disabled: false,
        }).text(this.config.downloadText);
    }

    bindClickEvent(){
        $('body').on('click', this.btnSelector, ()=> {
            this.setBtnDownload();

            /*下面的为原生的保存，不带格式名*/
            // 这将会提示用户保存PNG图片
            // Canvas2Image.saveAsPNG(oCanvas);
            saveFile(this.IMG_DATA, this.config.fileName);
        });
    }

    // initFile(){
    //     return this.createImg(this.config.imgBoxSelector).then((canvas)=> {
    //         return canvas;
    //     }).then((canvas)=>{
    //         /*自动保存为png*/
    //         // 获取图片资源
    //         this.IMG_DATA = Canvas2Image.saveAsPNG(canvas, true).getAttribute('src');
    //     });
    // }
    initFile(){
        return this.createImg(this.config.imgBoxSelector).then((canvas)=> {
            /*自动保存为png*/
            // 获取图片资源
            this.IMG_DATA = Canvas2Image.saveAsPNG(canvas, true).getAttribute('src');
        });
    }

}



class ajaxForm {
	constructor(formEl, listEl, option) {
		this.$formEl = $(formEl);
		this.$listEl = $(listEl);
		this.isLoading = false;
		
		var defaultOptions = {
			onSubmit: function () {
			}
		};
		this.option = Object.assign({}, defaultOptions, option);

		this.init();
	}
	

	init() {
		this.$listEl.parent().addClass('loading-box')
			.append(`<div class="loading-wrapper">
				<div class="loading">
		            <span></span>
		            <span></span>
		            <span></span>
		            <span></span>
		            <span></span>
		        </div>
	        </div>`);
		
		
		this.$formEl.on('submit', (e)=>{
			e.preventDefault();
			
			if(this.isLoading){
				return false;
			}
			this.enableLoadingStatus();
			this.showLoading();
			console.log("提交了");
			this.handleSubmit(e);
			
		});
	}
	
	enableLoadingStatus(){
		this.isLoading = true;
	}
	
	disableLoadingStatus(){
		this.isLoading = false;
	}
	
	showLoading(){
		this.$listEl.parent().addClass('loading-box__active');
	}
	
	hideLoading(){
		return new Promise((resolve)=>{
			this.$listEl.parent().removeClass('loading-box__active');
			resolve();
		});
	}
	
	reset(){
		this.hideLoading().then(()=>{
			this.disableLoadingStatus();
		});
	}
	
	handleSubmit(e){
		this.option.onSubmit(this.getParam());
	}
	
	getParam() {
		const PARAMS_ARR = this.$formEl.serialize().split('&');
		let resObj = {};
		PARAMS_ARR.forEach(function(item) {
			const PARAMS_ITEM_ARR = item.split('=');
			const KEY = PARAMS_ITEM_ARR[0];
			const VALUE = PARAMS_ITEM_ARR[1];
			resObj[KEY] = decodeURIComponent(VALUE);
		})
		return resObj;
	}

}


//编辑器设置
var swiperDefaultConf = {
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
    },
	pagination :{
		clickable :true,
	},
    loop: true
};

function initSwiper(container, confObj = {}) {
    var $container = $(container),
        conf = {},
	    pagination = {};
	
	confObj.pagination = confObj.pagination || {};
	Object.assign(pagination, swiperDefaultConf.pagination, confObj.pagination);
	Object.assign(conf, swiperDefaultConf, confObj);
	conf.pagination = pagination;
    
    if (!$container.length) {
        return false;
    }
    
    var mySwiper = new Swiper(container, conf);
    $(container).mouseleave(function () {
        mySwiper.autoplay.start();
    });
    
    return mySwiper;
}
var getQueryVariable = function(variable){
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
    }
    return(false);
}
var loadMore = function (contentEle, url, params = {}, options = {}) {
    let $el = $(contentEle);
    let defOptions = {
        fnBefore(v){
            return v;
        },
        fnSuccess(){},
        fnFail(){},
    };
    options = Object.assign({},defOptions, options);
    let defParams = {
        page: 2,
        per_page: 10,
    };
    params = Object.assign({}, defParams,params);
    let dropload = $el.parent().dropload({
        scrollArea : window,
        loadDownFn : function(me){
            params = options.fnBefore(params,me);
            $.ajax({
                type: 'GET',
                url,
                data: params,
                success: function (res) {
                    const HAS_DATA = $(res).find(contentEle).children().length;
                    if(HAS_DATA){
                        params.page++;
                        $el.append($(res).find(contentEle).html());
                        setTimeout( ()=> {
                            // 每次数据加载完，必须重置
                            me.resetload();
                        });
                    }else{
                        me.lock();
                        me.noData();
                        me.resetload();
                    }
                    options.fnSuccess(res);
                },
                error: function (e) {
                    me.resetload();
                    options.fnFail(e);
                }
            });
        }
    });


    return {
        update(newParams){
            $el.empty();
            newParams.page = 1;
            params = Object.assign({}, params, newParams);
            dropload.unlock();
            dropload.noData(false);
    
            return new Promise((resolve, reject)=>{
    
                dropload.resetload();
    
                var fnSuccess = options.fnSuccess;
                options.fnSuccess = ()=>{
                    fnSuccess();
                    resolve();
                };
    
                var fnFail = options.fnFail;
                options.reject = ()=>{
                    fnFail();
                    reject();
                };
    
            });
        }
    };
};

function loadMoreWithAjaxForm(loadMoreParams, ajaxFormParams){
    var res = loadMore(...loadMoreParams);
    
    var form_ajax = new ajaxForm(...ajaxFormParams, {
        onSubmit: function(param) {
            res.update(param).finally(function (){
                form_ajax.reset();
            });
        }
    });
}


function loadMoreMulti(conf) {
    if(!Array.isArray(conf)){
        return false;
    }
    let $clickEl = $('.loadmore-click-tab-js');
    let index = 0;
    let data = conf[index];
    let lock = function(dropload){
        // 锁定
        dropload.lock();
        dropload.noData();
    };

    let unLock = function(dropload){
        // 解锁
        dropload.unlock();
        dropload.noData(false);
    };
    conf.forEach((item)=>{
        item.loadEnd = false;
        item.params.page = 1;
    });

    let dropload = $('.loadmore-tab-js').dropload({
        scrollArea : window,
        loadDownFn : function(me){
            if(data.loadEnd){
                lock(me);
                me.resetload();
                return false;
            }
            if(!$clickEl.eq(index).hasClass('active')){
                lock(me);
                me.resetload();
                return false;
            }
            data.params.page++;
            $.ajax({
                type: 'GET',
                url: data.url,
                data: data.params,
                success: function (res) {
                    if(!$(res).find(data.el).children().length){
                        lock(me);
                        me.resetload();
                        data.loadEnd = true;
                        return false;
                    }
                    $(data.el).append($(res).find(data.el).html());
                    typeof data.options.fnSuccess === 'function' && data.options.fnSuccess(res);
                    setTimeout( ()=> {
                        // 每次数据加载完，必须重置
                        me.resetload();
                    })
                },
                error: function () {
                    data.params.page--;
                    me.resetload();
                }
            });
        }
    });

    $clickEl.on('click', function () {
        index = $clickEl.index(this);
        data = conf[index];
        if(!data){
            index = $clickEl.length - index;
            data = conf[index] || conf[index - $clickEl.length];
        }
        if(data.loadEnd){
            lock(dropload);
        }else{
            unLock(dropload);
        }
        // 重置
        setTimeout( ()=> {
            dropload.resetload();
        });
    });

}

function updateVerifyCode(){
    $('.img-verify-code img').each(function (index, el){
        var srcBeforeT = $(el).attr('src').split('=')[0];
        $(this).attr({
            src: srcBeforeT + (new Date().getTime()),
        })
    });
}

function handleSendCodeRequest(url, params){
    params = params || {};
    return new Promise(function (resolve, reject){
        return $.ajax({
            url,
            data: {
                code: params.code,
                mobile: params.mobile,
            },
            success: function (res){
                showMsg(res.info);
                if(Number(res.status)){
                    resolve(res.status);
                }else{
                    updateVerifyCode();
                    reject(res.info);
                }
            },
            fail: function (err){
                updateVerifyCode();
            }
        });
    })
}

function confirmBox(options = {}){
    return new Promise((resolve, reject)=>{
        const DEFAULT_OPTIONS = {
            content: '',
            buttons: [
                {
                    value: '取消'
                },
                {
                    value: '确定'
                },
            ],
            success: function (v) {
                if(v === '确定'){
                    resolve(v);
                }else{
                    reject(v);
                }
            },
        };
        const OPTIONS = Object.assign({}, DEFAULT_OPTIONS, options);
        $.msgBox(OPTIONS);
    });
}

function getLineNumber($el){
    // 行数 = 高度 / 行高
    const LINE_HEIGHT = parseFloat($el.css('line-height'));
    const HEIGHT = parseFloat($el.height());
    return parseInt(HEIGHT / LINE_HEIGHT);
}
// 点击展开
function initArticleWrapper(){
    $('.article-wrapper').each((index, el)=>{
        const LINE_NUMBER = getLineNumber($(el));
        const LIMIT_LINE_NUMBER = 3;
        const SHOW_ACTIVE = LINE_NUMBER > LIMIT_LINE_NUMBER;
        if(SHOW_ACTIVE){
            const LINE_HEIGHT = parseFloat($(el).css('line-height'));
            const LIMIT_HEIGHT_REM = px2rem(LINE_HEIGHT * LIMIT_LINE_NUMBER);
            $(el).addClass('active').css({
                'max-height': `${LIMIT_HEIGHT_REM}rem`
            });
        }
    })
    $('body').on('click', '.article-wrapper .show-all', function () {
        $(this).parents('.article-wrapper').removeClass('active').css({
            'max-height': `unset`
        });
    });
}

function px2rem(px){
    const ROOT_FONT_SIZE = parseFloat($('html').css('font-size'));
    return px / ROOT_FONT_SIZE;
}

function rem2px(rem){
    const ROOT_FONT_SIZE = parseFloat($('html').css('font-size'));
    return rem * ROOT_FONT_SIZE;
}

$(function () {
    initArticleWrapper();
    
    $('.search-form .search-tag-ul').each(function (index,ul) {
        var $form = $(ul).parent('form');
        var name = $(ul).data('name');
        $(ul).find('li').on('click',function () {
            var val = $(this).data('val');
            $(this).addClass('active').siblings().not(this).removeClass('active');
            $form.find('[name='+name+']').val(val);
            setTimeout(function () {
                $form.trigger('submit');
            });
        });
    });
    

    
    $('.tab-top-menu a').on('click', function () {
        $(this).parents('.tab-top-menu').find('a').removeClass('active');
        $(this).addClass('active');
    });

    $('.img-gallery').each((index, item)=>{
        let $el = $(item);
        let childTagName = '';
        try{
            childTagName = $el.children().first().get(0).tagName;
        }catch(e){
        }
        if(childTagName){
            $el.magnificPopup({
                delegate: childTagName,
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: false,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                image: {
                    verticalFit: true,
                    titleSrc: function(item) {
                    }
                },
                gallery: {
                    enabled: true
                },
                zoom: {
                    enabled: true,
                    duration: 300, // don't foget to change the duration also in CSS
                    opener: function(element) {
                        return element.find('img');
                    }
                }

            })
        }
    });
});


$(function () {
    $('.page-module-fold .title').on('click', function (){
        $(this).toggleClass('active mb0');

        var $foldArea = $(this).siblings('.fold-area');
        $foldArea.toggleClass('active grow');
    });

    $('body').on('click', '.btn-link', function (e){
        e.stopPropagation();
        e.preventDefault();
        window.location.href = $(this).data('href');
    })
});
